import React from 'react';
import Badge from 'react-bootstrap/Badge';
import parse from 'html-react-parser';

export default function ReleaseNoteSection({ id, releaseName, releaseDate, summary, newFeatures, backcompatIssues, improvements, errata, issuesAddressed, knownIssues, isLatest, isDeprecated, isUnsupported, isPreview }) {
  return (
    <React.Fragment>
      <hr />
      <h2 id={id} style={{ display: 'inline', verticalAlign: 'top' }} className='model-name'>Release {releaseName} </h2>
      {isLatest && <Badge pill className='primary-color-background'>Latest</Badge>}
      {isPreview && <Badge pill className='light-primary-color-background'>Preview</Badge>}
      {isDeprecated && <Badge pill variant="warning">Deprecated</Badge>}
      {isUnsupported && <Badge pill variant="danger">Not Supported</Badge>}

      <p>Released: {releaseDate}</p>
      <h4>Summary</h4>
      <ul>
        {Array.isArray(summary) ? (
          summary.map((item, i) => {
            return (
              <div>
                {Array.isArray(item) ? (
                  <ul>
                    {item.map((subItem, j) => (
                      <li key={j}>{subItem}</li>
                    ))}
                  </ul>
                ) : (
                  <li key={i}>
                    {item}
                  </li>
                )}
              </div>
            );
          })
        ) : (
          <li>{summary}</li>
        )}
      </ul>
      <h4>New Features</h4>
      <ul>
        {newFeatures && newFeatures.length > 0 ?
          newFeatures.map((feature, i) => {
            return (<li key={i}>{parse(feature)}</li>)
          })
          : <li>N/A</li>}
      </ul>

      <h4>Non-backward Compatible Changes</h4>
      <p>The following requires changes when upgrading to this version</p>
      <ul>
        {backcompatIssues && backcompatIssues.length > 0 ?
          backcompatIssues.map((feature, i) => {
            return (<li key={i}>{feature}</li>)
          })
          : <li>N/A</li>}
      </ul>
        {improvements && improvements.length > 0 ?
        <div>
          <h4>Improvements</h4>
          <ul>
              {improvements.map((feature, i) => {
                return (<li key={i}>{feature}</li>)
              })}
          </ul>
        </div>
        : <div></div> }
      <h4>Issues Addressed</h4>
      <ul>
        {issuesAddressed && issuesAddressed.length > 0 ?
          issuesAddressed.map((feature, i) => {
            return (<li key={i}>{feature}</li>)
          })
          : <li>N/A</li>}
      </ul>

      <h4>Errata</h4>
      <ul>
        {errata && errata.length > 0 ?
          errata.map((err, i) => {
            return (<li key={i}>{parse(err)}</li>)
          })
          : <li>N/A</li>}
      </ul>

      <h4>Known Issues</h4>
      <ul>
  {knownIssues && knownIssues.length > 0 ? (
    knownIssues.map((issue, i) => {
      return (
        <div>
          {Array.isArray(issue) ? (
            <ul>
              {issue.map((subIssue, j) => (
                <li key={j}>{subIssue}</li>
              ))}
            </ul>
          ) : (
            <li key={i}>
              {issue}
            </li>
          )}
        </div>
      );
    })
  ) : (
    <li>N/A</li>
  )}
</ul>
    </React.Fragment>
  )
}
